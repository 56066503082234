import * as React from "react"
import { Link, type HeadFC } from "gatsby"
import { graphql } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}


export const query = graphql`
query BlogIndexQuery($lang: String!) {
    allMarkdownRemark(
        filter: {
            fileAbsolutePath: {regex: "/blog/"},
            frontmatter: {lang: {eq: $lang}}
        }
        sort: { frontmatter: {date: DESC} }
    ) {
        edges {
            node {
                frontmatter {
                    title
                    slug
                    date
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 200)
                        }
                    }
                }
                excerpt(format: HTML, pruneLength: 300, truncate: true)
                html
            }
        }
    }
}
`


const IndexPage = ({data, pageContext}: any) => {
    let bannerTitle = "Besser fühlen"
    let bannerSubtitle = "Blog für mentale Gesundheit"
    if(pageContext.lang == 'en') {
        bannerTitle = "Feel better"
        bannerSubtitle = "Mental Health Blog"
    }

  const readOnLabel = pageContext.lang == 'de' ? 'Weiterlesen' : 'Continue reading'

  return (
    <Layout lang={pageContext.lang}>
        {/*<div className="banner blog">
            <h1>{bannerTitle}</h1>
            <p>{bannerSubtitle}</p>
        </div>
*/}
        <div className="articleList">
            {
            data.allMarkdownRemark.edges.map(({node}: any) => {
                const image = getImage(node.frontmatter.image?.childImageSharp.gatsbyImageData)
                const date = new Date(node.frontmatter.date).toLocaleString(pageContext.lang, dateOptions)
                const { excerpt, html } = node;
                const hasMoreText = html.length > excerpt.length;

                return (
                    <div className="articleContainer">
                        <Link to={node.frontmatter.slug}>
                            { image &&
                            <GatsbyImage
                                image={image}
                                alt={node.frontmatter.title}
                                style={{borderRadius: '50%', marginTop: 30, margin:30, width: 200, height: 200}}
                            />
                            }

                            {!image && <StaticImage
                            src={`../images/blog/placeholder.jpeg`}
                            alt="Blog Post"
                            placeholder="blurred"
                            layout="fixed"
                            width={200}
                            height={200}
                            //style={{borderRadius: '50%', marginTop: 30, marginRight: 30, marginLeft: 30}}
                        />}
                        </Link>

                        <div style={{flex: 1, marginLeft: 30}}>
                            <article dangerouslySetInnerHTML={{__html: node.excerpt}} />
                            { hasMoreText &&
                            <Link to={node.frontmatter.slug} style={{fontSize: '1.2em'}}>&raquo; {readOnLabel}</Link>
                            }
                        </div>

                    </div>
                )
            })
            }
        </div>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    return <Headers
        lang={pageContext.lang}
        title="Blog"
        location={location}
        />
}
